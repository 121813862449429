import axios from "axios"
import StatusService from "./statusService"
import { endpoint } from "../dataService";

class Constraints {

    constructor() {
        this.url = `${endpoint()}/api/v1/constraint_num_hours`
    }

    async getShopsHours(token, start, end, type, id) {

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + `?type=${type}&start=${start}&end=${end}&id=${id}`, config)
        // return await axios.get(this.url + '?type=shop&start=2023-10-01&end=2023-10-30&id=1', config)

            .then(function (response) {

                // console.log('SHOPS TOT HOURS: ', response.data.data)
                return response.data.data

            })

            .catch(function (error) {

                console.log('GET SHOP HOURS ERROR: ', error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }
}

export default new Constraints()

    
