<template>
    <div class="main">
        <PageTitle @back="back" />
        <div class="window" :class="(visible) ? ' no-border' : ''">
            <div class="summary">
                <Scheduler v-if="visible" :scheduleDate="startDate.split('-').reverse().join('-')" :weekNumber="weekNr" :year="year" :worker="worker"
                    @back="back" :shops="false" :panoramic="true" :block="true" @verify="openConstraints" @shopVerify="verifyShops" @daysOff="openDaysOff" />
                <div v-if="!visible" class="section section--form">
                    <div class="form-container">
                        <FormSelect :scheduleList="true" :item-list="weeksList" labelText="Settimana" v-model:value="schedule" />
                        <FormSelect labelText="Operatore" :workerSelect="true" :item-list="workersList" v-model:value="worker" />
                    </div>
                    <div class="summary-buttons">
                        <Button buttonType="brand" :rounded="true" text="Visualizza" icon="visibility" @click="summary()" />
                        <Button buttonType="brand" :rounded="true" text="Genera Report" icon="download" @click="generateReport()" />
                        <Button v-if="createReport" buttonType="brand" :rounded="true" text="Download" icon="download" @click="download()" />
                        <!-- <JsonExcel  class="button button--brandFull button--rounded"
                            :name="`Riepilogo_${startDate}-${endDate}_${name}`"
                            :data="itemsList" :fields="json_fields" :footer="footer" @click="download()">
                            <svg class="icon icon-download">
                                <use xlink:href="#download"></use>
                            </svg>

                            Download
                        </JsonExcel> -->
                        <vue3-html2pdf v-show="!isMobile" :show-layout="false" :float-layout="true" :enable-download="true"
                            :preview-modal="true" :paginate-elements-by-height="120" :filename="fileName" :pdf-quality="2"
                            :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="100%"
                            ref="html2Pdf" @hasDownloaded="hasDownloaded">
                            <template v-slot:pdf-content>
                                <PdfTemplate :object="pdfReport" :daysOff="daysOff" />
                            </template>
                        </vue3-html2pdf>
                        <Button v-if="schedule !== null" buttonType="brand" :rounded="true" text="Genera PDF"
                            icon="download" @click="generatePdf()" />
                    </div>
                    <div v-if="dateError" class="date-error error">
                        La data è obbligatoria
                    </div>
                </div>

            </div>
        </div>
        <Constraints v-if="constraints" :year="year" :week_number="weekNr" :group_id="groupId" @close="closeConstraints"/>
        <DaysOffModal v-if="daysOffOpen" :buttons="false" :week="weekNr" :year="year" @close="close" />
        <ShopConstraints v-if="shopVerify" :year="year" :week_number="weekNr" :schedule-id="0" @close="close"/>
        <Modal v-if="wait" title="Attendere la fine del download" noButton="no-button" />

    </div>
</template>

<script>

import Button from '../../components/tools/Button.vue';
import Constraints from '../../components/Constraints.vue';
import DaysOffService from '../../services/daysOffService';
import DaysOffModal from '../../components/days-off/DaysOffModal.vue';
import ExportService from '../../services/exportService';
import FormSelect from '../../components/tools/FormSelect.vue';
import PageTitle from '../../components/PageTitle.vue';
import PdfTemplate from '../../components/tools/PdfTemplate.vue';
import ShopConstraints from '../../components/ShopConstraints.vue';
import ScheduleService from '../../services/scheduleService';
import Scheduler from '../../components/Scheduler.vue';
import ShiftsObjects from '../../services/shiftsObjects';
import ShiftsService from '../../services/shiftsService';
import Modal from '../../components/tools/Modal.vue';
// import JsonExcel from 'vue-json-excel3';
import Vue3Html2pdf from 'vue3-html2pdf';
import { totHoursCounter, alphabetic, formatExcelJson } from '../../dataService';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx/xlsx.mjs'

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export default {
    name: 'summary-page',
    components: {
        Button,
        Constraints,
        DaysOffModal,
        FormSelect,
        Modal,
        // JsonExcel,
        PageTitle,
        PdfTemplate,
        Scheduler,
        ShopConstraints,
        Vue3Html2pdf,
        // SummaryForm
    },
    props: {

    },
    data() {
        return {
            createReport: false,
            constraints: false,
            dateError: false,
            daysOff: null,
            daysOffOpen: false,
            endDate: null,
            heading: null,
            itemsList: null,
            // json_fields: null,
            pdfReport: [],
            reportYear: null,
            schedule: null,
            scheduleId: null,
            shopVerify: false,
            startDate: null,
            start_date: null,
            visible: false,
            wait: false,
            week: null,
            weekNr: null,
            weeksList: [],
            worker: null,
            workersList: [],
            year: null
        }
    },
    methods: {
        async generateReport() {

            if (this.schedule !== null && this.schedule !== '') {

                if (this.worker !== null && this.worker !== '') {
                    var reportData = {
                        start_date: (this.schedule !== null) ? this.startDate.split('-').reverse().join('-') : null,
                        end_date: (this.schedule !== null) ? this.endDate.split('-').reverse().join('-') : null,
                        type: 'user',
                        id: this.worker
                    }

                    this.itemsList = await ExportService.getExports(localStorage.getItem('token'), reportData)
                    // this.json_fields = this.user_fields
                    this.heading = [["Nome", "Punto vendita", "Data", "Dalle", "Alle", "Numero ore", "Note"]]
                    this.createReport = true
                    this.dateError = false

                }
                else {

                    this.itemsList = await ShiftsService.getShiftsAllWeek(
                        localStorage.getItem('token'),
                        this.year,
                        this.weekNr
                    )

                    // this.json_fields = this.week_fields
                    this.heading = [["Nome", "Punto vendita", "Data", "Dalle", "Alle", "Numero ore", "Note"]]
                    this.dateError = false
                    this.createReport = true

                }
            } else {
                this.dateError = true
            }

        },
        async generatePdf() {
            
            if(window.innerWidth < 768) this.wait = true;

            if (this.worker == null || this.worker == '') {

                this.pdfReport = await ShiftsObjects.getShiftObjectByWorker(
                    localStorage.getItem('token'),
                    this.year,
                    this.weekNr
                )

            } else {
                this.pdfReport = []

                this.pdfReport.push(await ShiftsObjects.getShiftObjectBySingleWorker(
                    localStorage.getItem('token'),
                    this.year,
                    this.weekNr,
                    this.worker
                ))
            }
            // console.log(Vue3Html2pdf)
            this.$refs.html2Pdf.generatePdf()
        },
        download() {
            const JSON = formatExcelJson(this.itemsList)
            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.json_to_sheet([])
            XLSX.utils.sheet_add_aoa(worksheet, this.heading)
            XLSX.utils.sheet_add_json(worksheet, JSON, { origin: 'A2', skipHeader: true })
            XLSX.utils.sheet_add_aoa(worksheet, [[this.footer]], { origin:-1 })
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Foglio 1')
            XLSX.writeFile(workbook, `${this.fileName}.xlsx`)
        },
        async summary() {
            if (this.schedule !== null && this.schedule !== '') {
                this.dateError = false
                this.visible = true

            } else if (this.schedule == null || this.schedule == '') {
                this.dateError = true
            }

        },
        back() {
            this.visible = false
        },
        openConstraints(){
            this.constraints = true;
        },
        closeConstraints(){
            this.constraints = false
        },
        hasDownloaded(){
            this.wait = false
        },
        verifyShops(){
            this.shopVerify = true
        },
        openDaysOff(){
            this.daysOffOpen = true
        },
        close(){
            this.shopVerify = false
            this.daysOffOpen = false
        }
    },
    computed: {
        footer() {
            var totHours = 0;
            Array.from(this.itemsList).map((item) => {
                totHours += totHoursCounter(item.time_start, item.time_end)
            })

            return 'Ore totali ' + totHours
        },
        fileName() {
            if (this.schedule !== null) {
                if (this.worker !== null && this.worker !== '') {
                    var items = JSON.parse(localStorage.getItem('workersList'))
                    var name
                    Array.from(items).map((item) => {
                        if (item.id == this.worker) name = item.full_name
                    })
                    return `Settimana ${this.weekNr} ${name}`
                } else {
                    return `Settimana ${this.weekNr}`
                }
            } else {
                return ''
            }
        },
        isMobile(){
            if(window.innerWidth > 768) return false; else return true;
        },
        name(){
            var name = ''
            if(this.worker !== null){

                Array.from(JSON.parse(localStorage.getItem('workersList'))).map((worker) => {
                    if(worker.id == this.worker) name = worker.full_name
                })
            }
            return name
        }
    },
    watch: {
        schedule(value) {
            this.createReport = false
            value = JSON.parse(value)
            const schedules = JSON.parse(localStorage.getItem('schedulesList'))

            Array.from(schedules).map(async (schedule) => {
                if (value.week_number == parseInt(schedule.week_number) && value.year == parseInt(schedule.year)) {
                    this.weekNr = value.week_number
                    this.year = value.year
                    this.startDate = value.start_date
                    this.endDate = value.end_date
                    this.daysOff = await DaysOffService.getWeekDaysOff(localStorage.getItem('token'), schedule.year, value.week_number)
                }
            })
        },
        visible(value){
            if(value) document.querySelector('.back-arrow').style.visibility = 'visible';
            else document.querySelector('.back-arrow').style.visibility = 'hidden'
        },
        worker(){
            this.createReport = false
        }
    },
    async mounted() {
        const workersList = JSON.parse(localStorage.getItem('workersList'))

        var operators = []

        Array.from(workersList).map((worker) => {
            if (worker.role == 'operatore' && worker.status == 'attivo') {
                operators.push(worker)
            }
        })

        this.workersList = operators.sort(alphabetic)

        this.weeksList = ScheduleService.schedulableWeeks()

        // console.log(XLSX.writeXLSX)
    }
}

</script>